import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/'
        , redirect: {path: 'login'}
    },{
        path: '/mainIndex',
        meta: {
            title: '首页',
        },
        component: () => import('../views/mainIndex.vue')
    },{
        path: '/goodsInOut',
        meta: {
            title: '产品进出v3',
        },
        component: () => import('../views/finishedProduct/GoodsInOut.vue')
    },
    {
        path: '/printIntoTheWarehouse',
        meta: {
            title: '打印-进仓单',
        },
        component: () => import('../views/print/PrintIntoTheWarehouse.vue')
    },
    {
        path: '/allWarehouse/allSalesBilling',
        meta: {
            title: '销售开单一览',
        },
        component: () => import('../views/salesOrders/AllSalesBilling.vue')
    },
    {
        path: '/invoiceAll',
        meta: {
            title: '发票开票一览',
        },
        component: () => import('../views/salesOrders/InvoiceAll.vue')
    },
    {
        path: '/productQuotation',
        meta: {
            title: '产品报价',
        },
        component: () => import('../views/salesOrders/ProductQuotation.vue')
    },
    {
        path: '/allWarehouse/productQuotationList',
        meta: {
            title: '产品报价一览',
        },
        component: () => import('../views/salesOrders/ProductQuotationList.vue')
    }, {
        path: '/transferSlip',
        meta: {
            title: '销售开单',
        },
        component: () => import('../views/salesOrders/TransferSlip.vue')
    }, {
        path: '/transferSlipInform',
        meta: {
            title: '开单通知书',
        },
        component: () => import('../views/salesOrders/TransferSlipInform.vue')
    }, {
        path: '/shippingAdvice',
        meta: {
            title: '发货通知书',
        },
        component: () => import('../views/salesOrders/ShippingAdvice.vue')
    },
    {
        path: '/allWarehouse/allTransferSlipInform',
        meta: {
            title: '开单通知书一览',
        },
        component: () => import('../views/salesOrders/AllTransferSlipInform.vue')
    },
    {
        path: '/salesOrdersSingle',
        meta: {
            title: '销售订单',
        },
        component: () => import('../views/salesOrders/SalesOrdersSingle.vue')
    },
    {
        path: '/allWarehouse/allSalesOrders',
        meta: {
            title: '销售订单一览',
        },
        component: () => import('../views/salesOrders/AllSalesOrders.vue')
    },
    {
        path: '/allWarehouse/allShippingAdvice',
        meta: {
            title: '发货单一览',
        },
        component: () => import('../views/salesOrders/AllShippingAdvice.vue')
    },
    {
        path: '/productSalesList',
        meta: {
            title: '产品销售排行榜',
        },
        component: () => import('../views/ranking/ProductSalesList.vue')
    },
    {
        path: '/salesRanking',
        meta: {
            title: '销售排名',
        },
        component: () => import('../views/ranking/SalesRanking.vue')
    },
    {
        path: '/companyRanking',
        meta: {
            title: '客户公司销售排名',
        },
        component: () => import('../views/ranking/CompanyRanking.vue')
    },
    {
        path: '/intoTheWarehouse',
        meta: {
            title: '进仓单',
        },
        component: () => import('../views/finishedProduct/into/IntoTheWarehouse.vue')
    }, {
        path: '/allIntoWarehouse',
        meta: {
            title: '进仓单一览',
        },
        component: () => import('../views/finishedProduct/into/AllIntoWarehouse.vue')
    },  {
        path: '/receivables',
        meta: {
            title: '应收账款明细',
        },
        component: () => import('../views/cashier/Receivables.vue')
    }, {
        path: '/outWarehouse',
        meta: {
            title: '出仓单',
        },
        component: () => import('../views/finishedProduct/out/OutWarehouse.vue')
    }, {
        path: '/replaceOutWarehouse',
        meta: {
            title: '换仓库出仓',
        },
        component: () => import('../views/finishedProduct/out/ReplaceOutWarehouse.vue')
    }, {
        path: '/machining',
        meta: {
            title: '加工生产',
        },
        component: () => import('../views/finishedProduct/out/Machining.vue')
    }, {
        path: '/allOutWarehouse',
        name: 'AllOutWarehouse',
        meta: {
            title: '出仓单一览',
        },
        component: () => import('../views/finishedProduct/out/AllOutWarehouse.vue')
    }, {
        path: '/everyDayPrice',
        meta: {
            title: '每天价格设置',
        },
        component: () => import('../views/finishedProduct/EveryDayPrice.vue')
    }, {
        path: '/everyDayPriceProfits',
        meta: {
            title: '每天价格-毛利计算',
        },
        component: () => import('../views/finishedProduct/EveryDayPriceProfits.vue')
    }, {
        path: '/everyDayPriceList',
        meta: {
            title: '每天价格-限价列表',
        },
        component: () => import('../views/finishedProduct/EveryDayPriceList.vue')
    },{
        path: '/costAccounting',
        meta: {
            title: '存货成本核算v5',
        },
        component: () => import('../views/finishedProduct/CostAccounting.vue')
    },  {
        path: '/createInventoryList',
        meta: {
            title: '生成盘点存货单v3',
        },
        component: () => import('../views/finishedProduct/CreateInventoryList.vue')
    },{
        path: '/detailWarehouse',
        meta: {
            title: '实时库存明细',
        },
        component: () => import('../views/finishedProduct/DetailWarehouse.vue')
    },
    {
        path: '/detailWarehouseBatch',
        meta: {
            title: '批号库存明细',
        },
        component: () => import('../views/finishedProduct/DetailWarehouseBatch.vue')
    },
    {
        path: '/detailWarehouseLengthWays',
        meta: {
            title: '实时库存明细-横向',
        },
        component: () => import('../views/finishedProduct/DetailWarehouseLengthWays.vue')
    }, {
        path: '/checkInventory',
        meta: {
            title: '盘点设置',
        },
        component: () => import('../views/finishedProduct/CheckInventory.vue')
    }, {
        path: '/receiptHedging',
        meta: {
            title: '冲',
        },
        component: () => import('../views/cashier/ReceiptHedging.vue')
    }, {
        path: '/receipt',
        meta: {
            title: '出纳单',
        },
        component: () => import('../views/cashier/Receipt.vue')
    },{
        path: '/invoice',
        meta: {
            title: '发票开票',
        },
        component: () => import('../views/cashier/Invoice.vue')
    },{
        path: '/invoice2',
        meta: {
            title: '发票开票2',
        },
        component: () => import('../views/cashier/Invoice2.vue')
    },
    {
        path: '/clientReceivable',
        meta: {
            title: '应收客户账',
        },
        component: () => import('../views/cashier/ClientReceivable.vue')
    },{
        path: '/cashBanks',
        meta: {
            title: '所有科目账-查账',
        },
        component: () => import('../views/cashier/CashBanks.vue')
    },{
        path: '/clientReceivableDetail',
        meta: {
            title: '应收客户账-客户明细',
        },
        component: () => import('../views/cashier/ClientReceivableDetail.vue')
    },{
        path: '/openInvoiceAll',
        meta: {
            title: '发票一览',
        },
        component: () => import('../views/cashier/OpenInvoiceAll.vue')
    },{
        path: '/interestMoney',
        meta: {
            title: '客户欠款利息',
        },
        component: () => import('../views/cashier/InterestMoney.vue')
    },{
        path: '/invoiceClientReceivableDetail',
        meta: {
            title: '应收客户账-客户明细',
        },
        component: () => import('../views/cashier/InvoiceClientReceivableDetail.vue')
    },{
        path: '/payableDetail',
        meta: {
            title: '应付账款-供应商明细',
        },
        component: () => import('../views/cashier/PayableDetail.vue')
    },{
        path: '/cashBanksDetail',
        meta: {
            title: '查账-明细',
        },
        component: () => import('../views/cashier/CashBanksDetail.vue')
    },{
        path: '/payable',
        meta: {
            title: '供应商应付账',
        },
        component: () => import('../views/cashier/Payable.vue')
    },{
        path: '/checkCashBill',
        meta: {
            title: '核查异常出纳单据',
        },
        component: () => import('../views/cashier/CheckCashBill.vue')
    },{
        path: '/checkVoucher',
        meta: {
            title: '核查凭证借贷不平',
        },
        component: () => import('../views/cashier/CheckVoucher.vue')
    },{
        path: '/paymentOrder',
        meta: {
            title: '付款单',
        },
        component: () => import('../views/cashier/PaymentOrder.vue')
    }, {
        path: '/allFinance',
        meta: {
            title: '出纳单据一览',
        },
        component: () => import('../views/cashier/AllFinance.vue')
    }, {
        path: '/outsourcing',
        meta: {
            title: '外购单据一览',
        },
        component: () => import('../views/cashier/Outsourcing.vue')
    }, {
        path: '/receivable',
        meta: {
            title: '应收客户账',
        },
        component: () => import('../views/cashier/Receivable.vue')
    }, {
        path: '/receivableDetail',
        meta: {
            title: '客户应收账明细',
        },
        component: () => import('../views/cashier/ReceivableDetail.vue')
    }, {
        path: '/salesDueReceivableDetails',
        meta: {
            title: '销售到期应收明细',
        },
        component: () => import('../views/cashier/SalesDueReceivableDetails.vue')
    }, {
        path: '/sms',
        meta: {
            title: '汇款短信',
        },
        component: () => import('../views/cashier/Sms.vue')
    },  {
        path: '/logger',
        meta: {
            title: '操作日志',
        },
        component: () => import('../views/cashier/Logger.vue')
    }, {
        path: '/printTransferSlip',
        meta: {
            title: '打印-销售开单',
        },
        component: () => import('../views/print/PrintTransferSlip.vue')
    },  {
        path: '/printReceipt',
        meta: {
            title: '打印-出纳凭证',
        },
        component: () => import('../views/print/PrintReceipt.vue')
    }, {
        path: '/printTransferSlipInform',
        meta: {
            title: '打印-开单通知书',
        },
        component: () => import('../views/print/PrintTransferSlipInform.vue')
    }, {
        path: '/printShippingAdvice',
        meta: {
            title: '打印发货单',
        },
        component: () => import('../views/print/PrintShippingAdvice.vue')
    },
    {
        path: '/customers',
        meta: {
            title: '客户信息',
        },
        component: () => import('../views/setting/Customers.vue')
    },
    {
        path: '/customersStart',
        meta: {
            title: '客户期初数',
        },
        component: () => import('../views/setting/CustomersStart.vue')
    },
    {
        path: '/supplierStart',
        meta: {
            title: '供应商应付期初',
        },
        component: () => import('../views/setting/SupplierStart.vue')
    },
    //财务路由
    {
        path: '/voucher',
        meta: {
            title: '填制凭证',
        },
        component: () => import('../views/finance/Voucher.vue')
    },
    {
        path: '/allVoucher',
        meta: {
            title: '凭证一览',
        },
        component: () => import('../views/finance/AllVoucher.vue')
    },
    {
        path: '/accountBalanceReport',
        meta: {
            title: '科目余额表',
        },
        component: () => import('../views/finance/AccountBalanceReport.vue')
    },
    {
        path: '/reportBalance',
        meta: {
            title: '资产负债表',
        },
        component: () => import('../views/finance/ReportBalance.vue')
    },
    {
        path: '/ReportBalanceProfit',
        meta: {
            title: '利润表',
        },
        component: () => import('../views/finance/ReportBalanceProfit.vue')
    },
    {
        path: '/reportBalanceTotalBook',
        meta: {
            title: '总账',
        },
        component: () => import('../views/finance/ReportBalanceTotalBook.vue')
    },
    {
        path: '/reportBalanceDetailBook',
        meta: {
            title: '明细账',
        },
        component: () => import('../views/finance/ReportBalanceDetailBook.vue')
    },
    {
        path: '/allWarehouse/allBilling',
        meta: {
            title: '所有单据(生成凭证)',
        },
        component: () => import('../views/salesOrders/AllBilling.vue')
    },
    {
        path: '/otherRevenue',
        meta: {
            title: '其他收入一览',
        },
        component: () => import('../views/finance/OtherRevenue.vue')
    }, {
        path: '/costExpend',
        meta: {
            title: '费用支出一览',
        },
        component: () => import('../views/finance/CostExpend.vue')
    }, {
        path: '/accountingTimeSetting',
        meta: {
            title: '会计时间设定',
        },
        component: () => import('../views/finance/AccountingTimeSetting.vue')
    }, {
        path: '/login',
        meta: {
            title: 'gt登陆',
        },
        component: () => import('../views/Login.vue')
    }, {
        path: '/account',
        name: 'account',
        meta: {
            title: '账户管理',
        },
        component: () => import('../views/system/Account.vue')
    }, {
        path: '/role',
        name: 'role',
        meta: {
            title: '角色管理',
        },
        component: () => import('../views/system/Role.vue')
    }, {
        path: '/systemSetting',
        name: 'systemSetting',
        meta: {
            title: '系统设置',
        },
        component: () => import('../views/system/SystemSetting.vue')
    }, {
        path: '/assigningRole',
        name: 'assigningRole',
        meta: {
            title: '角色分配',
        },
        component: () => import('../views/system/AssigningRole.vue')
        },
    {
        path: '/warehouseSetting',
        meta: {
            title: '仓库设置',
        },
        component: () => import('../views/setting/WarehouseSetting.vue')
    },
    {
        path: '/billingCompanySetting',
        meta: {
            title: '开单公司名',
        },
        component: () => import('../views/setting/BillingCompanySetting.vue')
    },
    {
        path: '/unitSetting',
        meta: {
            title: '计量单位',
        },
        component: () => import('../views/setting/UnitSetting.vue')
    },
    {
        path: '/category',
        meta: {
            title: '类别设置',
        },
        component: () => import('../views/setting/CategorySetting.vue')
    },
    {
        path: '/supplier',
        meta: {
            title: '供应商信息',
        },
        component: () => import('../views/setting/SupplierSetting.vue')
    },
    {
        path: '/otherExpenses',
        meta: {
            title: '其他费用',
        },
        component: () => import('../views/setting/OtherExpenses.vue')
    },
    {
        path: '/clearingForm',
        meta: {
            title: '结算方式',
        },
        component: () => import('../views/setting/ClearingForm.vue')
    },
    {
        path: '/warehouseEntryNote',
        meta: {
            title: '进仓类型',
        },
        component: () => import('../views/setting/WarehouseEntryNote.vue')
    },
    {
        path: '/taxRate',
        meta: {
            title: '税率设置',
        },
        component: () => import('../views/setting/TaxRate.vue')
    },
    {
        path: '/warehouseOutNote',
        meta: {
            title: '出仓类型',
        },
        component: () => import('../views/setting/WarehouseOutNote.vue')
    },
    {
        path: '/specification',
        meta: {
            title: '规格设置',
        },
        component: () => import('../views/setting/Specification.vue')
    },
    {
        path: '/summary',
        meta: {
            title: '出纳-摘要',
        },
        component: () => import('../views/setting/Summary.vue')
    },
    {
        path: '/subject',
        meta: {
            title: '会计科目',
        },
        component: () => import('../views/setting/Subject.vue')
    },
    {
        path: '/test/vxe',
        meta: {
            title: 'test',
        },
        component: () => import('../views/salesOrders/Test.vue')
    },
    {
        path: '/test/cascader',

        meta: {
            title: 'cascader',
        },
        component: () => import('../views/test/Cascader.vue')
    }

]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    const sessionId = window.sessionStorage.getItem('sessionId')
    if (to.path === '/login') {
        if (sessionId) {
            return next('/mainIndex')
        } else {
            return next()
        }
    }
    if (!sessionId) {
        return next('/login')
    } else {
        next()
    }
})
export default router
