<template>
        <el-header height="initial">
            <el-menu :default-active="activeIndex"  class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="/mainIndex">首页</el-menu-item>

                <el-submenu index="2" v-if="$_getActionKeyListBoolean(['/productQuotation','/allWarehouse/productQuotationList','/salesOrdersSingle','/allWarehouse/allSalesOrders',
                '/transferSlipInform','/allWarehouse/allTransferSlipInform','/shippingAdvice','/allWarehouse/allShippingAdvice','/transferSlip','/allWarehouse/allSalesBilling','/productSalesList',
                '/salesRanking'])">
                    <template #title>销售管理</template>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/productQuotation'])" index="/productQuotation">产品报价</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/productQuotationList'])" index="/allWarehouse/productQuotationList">产品报价一览</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/productQuotation','/allWarehouse/productQuotationList'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/salesOrdersSingle'])" index="/salesOrdersSingle">销售订单</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allSalesOrders'])" index="/allWarehouse/allSalesOrders">销售订单一览</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/salesOrdersSingle','/allWarehouse/allSalesOrders'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/transferSlipInform'])" index="/transferSlipInform">开单通知书</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allTransferSlipInform'])" index="/allWarehouse/allTransferSlipInform">开单通知书一览</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/transferSlipInform','/allWarehouse/allTransferSlipInform'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/shippingAdvice'])" index="/shippingAdvice">发货通知书</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allShippingAdvice'])" index="/allWarehouse/allShippingAdvice">发货通知书一览</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/shippingAdvice','/allWarehouse/allShippingAdvice'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/transferSlip'])" index="/transferSlip">销售开单：调拨单/欠款单</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allSalesBilling'])" index="/allWarehouse/allSalesBilling">销售开单一览</el-menu-item>
                    <el-submenu v-if="$_getActionKeyListBoolean(['/productSalesList','/salesRanking'])" index="">
                        <template slot="title">销售排名榜</template>
                        <el-menu-item v-if="$_getActionKeyListBoolean(['/productSalesList'])" index="/productSalesList">产品销售排行榜</el-menu-item>
                        <el-menu-item v-if="$_getActionKeyListBoolean(['/salesRanking'])" index="/salesRanking">业务员销售排名</el-menu-item>
                        <!--<el-menu-item v-if="$_getActionKeyListBoolean(['/salesRanking'])" index="/salesRanking?searchType=company">客户公司销售排名</el-menu-item>
                        -->
                        <el-menu-item v-if="$_getActionKeyListBoolean(['/companyRanking'])" index="/companyRanking">客户公司销售排名2</el-menu-item>
                        <!--<el-menu-item v-if="$_getActionKeyListBoolean(['/mySalesRanking'])" index="/mySalesRanking">个人销售清单</el-menu-item>-->
                    </el-submenu>
                </el-submenu>
                <el-submenu index="3" v-if="$_getActionKeyListBoolean(['/intoTheWarehouse','/allIntoWarehouse','/outWarehouse','/allOutWarehouse',
                '/costAccounting','/goodsInOut',
                '/replaceOutWarehouse','/everyDayPrice','/everyDayPriceProfits','/detailWarehouse','/inventory','/detailWarehouseLengthWays'])">
                    <template #title>成品管理</template>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/warehouseEntryBill'])" index="/intoTheWarehouse">进仓单</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allIntoWarehouse'])" index="/allIntoWarehouse">进仓单一览</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/warehouseEntryBill','/allWarehouse/allIntoWarehouse'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
<!--                    <el-menu-item v-if="$_getActionKeyListBoolean(['/warehouseOutBill'])" index="/outWarehouse">出仓单</el-menu-item>-->
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allOutWarehouse'])" index="/allOutWarehouse">出仓单一览</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/machining','/warehouseOutBill','/allWarehouse/allOutWarehouse'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/machining'])" index="/machining">加工生产</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/replaceWarehouseBill'])" index="/replaceOutWarehouse">换仓库出仓</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/everyDayPrice','/everyDayPriceProfits'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/everyDayPrice'])" index="/everyDayPrice">每天价格设置</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/everyDayPriceProfits'])" index="/everyDayPriceProfits">每天价格-毛利计算</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/everyDayPriceList'])" index="/everyDayPriceList">每天价格-限价列表</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/everyDayPrice','/everyDayPriceProfits'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/costAccounting'])" index="/costAccounting">存货成本核算v5</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/goodsInOut'])" index="/goodsInOut">产品进出v3</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/detailWarehouse'])" index="/detailWarehouse">实时库存明细</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/detailWarehouseLengthWays'])" index="/detailWarehouseLengthWays">实时库存明细-横向</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/detailWarehouseBatch'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/detailWarehouseBatch'])" index="/detailWarehouseBatch">实时库存明细-批号</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/detailWarehouseBatch'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/inventory/search'])" index="/checkInventory">盘点设置</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/createInventoryList/search'])" index="/createInventoryList">生成盘点存货单v3</el-menu-item>
                </el-submenu>
                <el-submenu index="4" v-if="$_getActionKeyListBoolean(['/sms','/receipt','/paymentOrder','/allFinance','/cashBanks','/outsourcing',
                '/receivable','/receivableDetail','/salesDueReceivableDetails','/otherRevenue','/costExpend','/accountingTimeSetting','/allIntoWarehouse'
                ,'/clientReceivable/search','/receivables/search','/interestMoney/search'])">
                    <template #title>出纳管理</template>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/sms'])" index="/sms">汇款短信</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/sms'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/receipt'])" index="/receipt?type=SKD">收款单</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/receipt'])" index="/receipt?type=FKD">付款单</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/receipt'])" index="/receipt?type=QTSRD">其他收入单</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/receipt'])" index="/receipt?type=YBFYD">一般费用单</el-menu-item>
                    <!--<hr v-if="$_getActionKeyListBoolean(['/receiptHedging'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/receiptHedging'])" index="/receiptHedging">应收冲应收</el-menu-item>-->
                    <hr v-if="$_getActionKeyListBoolean(['/allFinance/search','/allWarehouse/allIntoWarehouseCN','/allWarehouse/allOutWarehouseCN','/allWarehouse/allSalesBilling'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allFinance/search'])" index="/allFinance">出纳单据一览</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allIntoWarehouseCN'])" index="/allIntoWarehouse?type=CN">外购单浏览（出纳专用）</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allOutWarehouseCN'])" index="/allWarehouse/allSalesBilling?typeCN=CN">销售开单浏览（出纳专用）</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/allFinance','/allIntoWarehouse'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/receivables/search'])" index="/receivables">应收账款明细</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/clientReceivable/search'])" index="/clientReceivable">应收客户账</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/interestMoney/search'])" index="/interestMoney">客户欠款利息</el-menu-item>
                    <!--<el-menu-item v-if="$_getActionKeyListBoolean(['/clientReceivableDetail'])" index="/clientReceivableDetail">应收客户账-客户明细</el-menu-item>-->
                    <hr v-if="$_getActionKeyListBoolean(['/payable/search','/checkCashBill'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/payable/search'])" index="/payable">供应商应付账</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/checkCashBill'])" index="/checkCashBill">核查异常出纳单据</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/cashBanks/search'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/cashBanks/search'])" index="/cashBanks">所有科目账-查账</el-menu-item>
                    <!--<el-menu-item v-if="$_getActionKeyListBoolean(['/receivable'])" index="/receivable">应收客户账</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/receivableDetail'])" index="/receivableDetail">客户应收账明细</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/salesDueReceivableDetails'])" index="/salesDueReceivableDetails">销售到期应收明细</el-menu-item>-->
                </el-submenu>
                <el-submenu index="5" v-if="$_getActionKeyListBoolean(['/invoice','/invoice/searchWBInvoice2','/invoiceAll','/openInvoiceAll/search','/allWarehouse/allSalesBilling','/invoiceClientReceivableDetail/search'])">
                    <template #title>发票管理</template>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allWarehouse/allSalesBilling'])" index="/allWarehouse/allSalesBilling?typeCN=FP">销售开单浏览（发票专用）</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/openInvoiceAll/search'])" index="/openInvoiceAll">发票一览</el-menu-item>
                    <!--<el-menu-item v-if="$_getActionKeyListBoolean(['/invoice/searchWBInvoice2'])" index="/invoice2">发票开票 v2</el-menu-item>-->
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/invoiceClientReceivableDetail/search'])" index="/clientReceivable?searchType=invoice">发票开票 v3</el-menu-item>
                    <!--<el-menu-item v-if="$_getActionKeyListBoolean(['/invoice'])" index="/invoice">发票开票</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/invoiceAll'])" index="/invoiceAll">发票开票一览</el-menu-item>-->
                </el-submenu>
                <el-submenu v-if="$_getActionKeyListBoolean(['/reportBalanceDetailBook','/reportBalanceTotalBook','/reportBalance','/voucher/searchVoucherById','/allVoucher/search','/subject','/accountingTimeSetting/search','/accountBalanceReport'])" index="6">
                    <template #title>财务管理</template>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allFinance/search'])" index="/allFinance?type=PZ">出纳单据一览（凭证专用）</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/accountBalanceReport'])" index="/accountBalanceReport">科目余额表</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/reportBalance'])" index="/reportBalance">资产负债表</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/reportBalanceProfit'])" index="/reportBalanceProfit">利润表</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/reportBalanceTotalBook','/reportBalanceDetailBook'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/reportBalanceTotalBook'])" index="/reportBalanceTotalBook">总账</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/reportBalanceDetailBook'])" index="/reportBalanceDetailBook">明细账</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/reportBalanceTotalBook','/reportBalanceDetailBook'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/voucher/searchVoucherById'])" index="/voucher">填制凭证</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allVoucher/search'])" index="/allVoucher">凭证一览</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/allVoucher/search'])" index="/allWarehouse/allBilling">所有单据(生成凭证)</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/subject'])" index="/subject?type=CSH">账套初始化</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/checkVoucher'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/checkVoucher'])" index="/checkVoucher">核查凭证借贷不平</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/accountingTimeSetting'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <!--<el-menu-item v-if="$_getActionKeyListBoolean(['/otherRevenue'])" index="/otherRevenue">其他收入</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/costExpend'])" index="/costExpend">费用支出</el-menu-item>-->
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/accountingTimeSetting/search'])" index="/accountingTimeSetting">会计时间设定</el-menu-item>
                </el-submenu>
                <el-submenu index="7" v-if="$_getActionKeyListBoolean(['/taxRate','/summary','/subject','/customers','/supplier','/specification','/billingCompanySetting','/warehouseSetting','/unitSetting','/categorySetting','/otherExpenses','/clearingForm','/warehouseEntryNote','/warehouseOutNote'])">
                    <template #title>基础资料</template>
                    <el-submenu v-if="$_getActionKeyListBoolean(['/start','/voucher'])" index="">
                        <template slot="title">期初数</template>
                        <el-menu-item v-if="$_getActionKeyListBoolean(['/start/customersStart'])" index="/customersStart">客户应收</el-menu-item>
                        <el-menu-item v-if="$_getActionKeyListBoolean(['/start/supplierStart'])" index="/supplierStart">供应商应付</el-menu-item>
                        <el-menu-item v-if="$_getActionKeyListBoolean(['/voucher'])" index="/subject?type=CSH">银行现金期初数</el-menu-item>
                    </el-submenu>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/summary'])" index="/summary">出纳-摘要</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/subject'])" index="/subject">会计科目</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/summary','/subject'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/customers'])" index="/customers">客户信息</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/supplier'])" index="/supplier">供应商信息</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/customers','/supplier','/specification'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/specification'])" index="/specification">规格设置</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/billingCompanySetting'])" index="/billingCompanySetting">开单公司名</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/warehouseSetting'])" index="/warehouseSetting">仓库设置</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/unitSetting'])" index="/unitSetting">计量单位</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/categorySetting'])" index="/category">类别设置</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/otherExpenses'])" index="/otherExpenses">其他费用</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/clearingForm'])" index="/clearingForm">结算方式</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/warehouseEntryNote'])" index="/warehouseEntryNote">进仓类型</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/warehouseOutNote'])" index="/warehouseOutNote">出仓类型</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/taxRate'])" index="/taxRate">税率设置</el-menu-item>
                    <hr v-if="$_getActionKeyListBoolean(['/billingCompanySetting','/warehouseSetting','/unitSetting','/category','/otherExpenses','/clearingForm','/warehouseEntryNote','/warehouseOutNote'])" style=" width: 180px; margin-top: 0px; margin-bottom: 0px; ">
                </el-submenu>

                <el-submenu index="8" v-if="$_getActionKeyListBoolean(['/account','/role','/logger','/systemSetting'])">
                    <template #title>系统维护</template>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/account'])" index="/account">账户管理</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/role'])" index="/role">角色管理</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/systemSetting'])" index="/systemSetting">系统设置</el-menu-item>
                    <el-menu-item v-if="$_getActionKeyListBoolean(['/logger'])" index="/logger">操作日志</el-menu-item>
                </el-submenu>
                <el-menu-item>
                    <el-dropdown>
                        <i class="el-icon-setting" style="margin-right: 15px"></i>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click.native="$refs.Account.editRowEvent($store.getters.getLoginAccount.id)">修改密码</el-dropdown-item>
                                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <span>{{$store.getters.getLoginAccount.nick_name}}</span>

                </el-menu-item>
                <el-menu-item @click="newWindow()">新建当前页</el-menu-item>
            </el-menu>
            <Account ref="Account"></Account>
        </el-header>

</template>

<script>
    import Account from "../../components/Account";
    export default {
        components: {Account},
        name: "TOP",
        data(){
            return {
                activeIndex: '/SalesOrders',
           }
       },
        created(){
            //console.log(this.$store.getters.getLoginAccount.isInitialPw)
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                if (this.$store.getters.getLoginAccount.isInitialPw){
                    this.$refs.Account.editRowEvent(this.$store.getters.getLoginAccount.id);
               }
           })

       },
        methods:{
            newWindow(){
                //const{href} = this.$router.resolve();
                //console.log(window.location.href)
                window.open(window.location.href,'_blank');
           },
            handleSelect(key) {
                this.$router.push(key);
           },logout(){
                window.sessionStorage.clear();
                // window.open("/", "_self");
                this.$router.push("/login");
           }
       }
   }
</script>

<style scoped>

</style>
