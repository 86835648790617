<template>
    <div>
        <!--新增账号-->
        <el-dialog width="420px" :title="showEdit ? '编辑账号' : '新增账号'" :visible.sync="dialogFormVisible" center
                   :close-on-click-modal="false">
            <el-form ref="accountForm" :model="accountForm" :rules="accountFormRules">
                <el-form-item label="姓名:" prop="nick_name">
                    <el-input size="mini" v-model="accountForm.nick_name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机:" prop="user_name">
                    <el-input size="mini" v-model="accountForm.user_name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="快捷键:" prop="shortcut_key">
                    <el-input size="mini" v-model="accountForm.shortcut_key" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码:" prop="pass_word" :style="showEdit ?' margin-left: 10px;':'' ">
                    <el-input size="mini" v-model="accountForm.pass_word" show-password auto-complete="new-password"
                              :placeholder="showEdit ?'留空不修改密码，必须包含字母+数字，6~15位':''"></el-input>
                </el-form-item>
                <el-form-item label="角色:" prop="roles" v-if="$_getActionKeyListBoolean(['/account/save'])">
                    <el-select size="mini" v-model="accountForm.roles" multiple placeholder="可搜索查询" style="width: 80%;">
                        <el-option
                                v-for="item in roleList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开票公司:" prop="billing_company_id">
                    <el-select size="mini"
                               @change="accountForm.billing_company_id = $event.id,accountForm.billing_company_name = $event.name"
                               v-model="accountForm.billing_company_name"
                               clearable
                               filterable placeholder="可搜索查询" style="width: 80%;">
                        <el-option
                                v-for="item in $store.getters.getBillingCompanyList"
                                :key="item.id"
                                :label="item.name"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAccount('accountForm','save')">确 定</el-button>
            </div>
        </el-dialog>
        <!--批量添加账号-->
        <el-dialog width="420px" :title="dialogBatchUpdate ? '批量修改角色' : '批量添加账号'" :visible.sync="dialogBatch" center
                   :close-on-click-modal="false">
            <el-form ref="accountFormBatch" :model="accountForm" :rules="accountFormRules">
                <el-tooltip placement="top-start">
                    <div slot="content">多个名字之间请用（空格）或，或,或；或;进行分隔。<br/>例如：小明 小红</div>
                    <el-form-item label="姓名:" label-width="68px" prop="nickNameList">
                        <el-input
                                type="textarea"
                                autocomplete="off"
                                :rows="2"
                                placeholder="请输入内容"
                                style="width: 90%;"
                                :readonly="this.dialogBatchUpdate"
                                v-model="accountForm.nickNameList">
                        </el-input>
                    </el-form-item>
                </el-tooltip>
                <template v-if="this.dialogBatchUpdate">
                    <el-radio border size="mini" style="margin-left: 70px;margin-top: 10px"
                              v-model="accountForm.isRolesTypeSave" :label="true">添加角色
                    </el-radio>
                    <el-radio border size="mini" v-model="accountForm.isRolesTypeSave" :label="false">删除角色</el-radio>
                </template>
                <el-form-item label="角色:" label-width="68px" prop="roles">
                    <el-select size="mini" v-model="accountForm.roles" multiple placeholder="可搜索查询" style="width: 90%;">
                        <el-option
                                v-for="item in roleList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="启用:" label-width="68px">
                    <el-switch v-model="accountForm.state"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogBatch = false">取 消</el-button>
                <el-button type="primary" @click="saveAccount('accountFormBatch','batchSaveOrUpdate')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "Account",
    props: {

   },
    data() {
        return {
            isTable:false,
            dialogFormVisible: false,
            dialogBatch: false,
            dialogBatchUpdate: false,
            showEdit: false,
            accountForm: {
                nick_name: '',
                isBatchSaveType: true,
                nickNameList: '',
                user_name: '',
                pass_word: '',
                role_name: '',
                shortcut_key: '',
                state: true,
                roles: [],
                isRolesTypeSave: true,
                billing_company_id: '',
                billing_company_name: '',
           },
            roleList: [],
            //表单验证规则
            accountFormRules: {
                nick_name: [
                    {required: true, message: "请输入姓名", trigger: "blur"},
                ],
                nickNameList: [
                    {required: true, message: "姓名不能为空", trigger: "blur"},
                ],
                user_name: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    // 这个只能验证手机号
                    // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur"}
                    {pattern: /^((0\d{2,3}-\d{7,8})|(1[135784]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                ],
                pass_word: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
               },
                    {
                        min: 6,
                        max: 15,
                        message: '密码长度在 6 到 15 个字符',
                        trigger: 'blur'
                   }
                ],
                roles: [
                    {required: false, message: "请输入查询角色权限", trigger: "blur"},
                ],
           },
       }
   },

    created() {
        this.getRoleList();
        //this.$_getBillingCompanySettingList('');//作废，开票公司根据不同用户设置
        this.isTable = false
   },
    methods: {
        doBatchUpdteEvent() {
            //获取所勾选的值，如果没有勾选则提示先勾选
            this.accountForm.isBatchSaveType = false;
            let selectRecords = this.$parent.$refs.xGrid.getCheckboxRecords();//vue子组件调用父组件ref
            //console.log('selectRecords', selectRecords);
            this.accountForm.nickNameList = ''
            selectRecords.forEach(item => {
                this.accountForm.nickNameList = item.nick_name + ',' + this.accountForm.nickNameList;
           });
            if (selectRecords == null || selectRecords.length == 0) {
                this.$XModal.alert('请先选择需要修改的账户！')
           } else {
                this.dialogBatchUpdate = true
                this.dialogBatch = true;
           }
       },
        getRoleList() {
            //获取RoleList
            this.$axios({
                method: 'post',
                url: '/admin/account/assignRolesListVue'
           }).then((response) => {          //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    this.roleList = response.data.roleList;
               }
           }).catch((error) => {
                console.log(error)
           });
       },
        addAccount() {
            this.accountFormRules.pass_word[0].required = true;
            this.dialogFormVisible = true;
            this.showEdit = false;
            this.accountForm.pass_word = '';
            this.accountForm.nick_name = '';
            this.accountForm.nickNameList = '';
            this.accountForm.user_name = '';
            this.accountForm.id = '';
            this.accountForm.billing_company_id = '';
            this.accountForm.billing_company_name = '';
            this.accountForm.roles = [];
       },
        editRowEvent(accountId) {
            //console.log('accountId',accountId)
            this.showEdit = true;
            this.dialogFormVisible = true;
            this.accountForm.pass_word = '';
            this.$axios({
                method: 'post',
                url: '/admin/account/editVue',
                params: {
                    id: accountId
               }
           }).then((response) => {          //这里使用了ES6的语法
                //console.log(response)       //请求成功返回的数据
                if (response.status === 200) {
                    this.accountForm.nick_name = response.data.account.nick_name;
                    this.accountForm.user_name = response.data.account.user_name;
                    this.accountForm.id = response.data.account.id;
                    this.accountForm.shortcut_key = response.data.account.shortcut_key;
                    this.accountForm.billing_company_id = response.data.account.billing_company_id;
                    this.accountForm.billing_company_name = response.data.account.billing_company_name;
                    this.accountFormRules.pass_word[0].required = false;
                    let rList = [];
                    for (let r in response.data.account.role_name) {
                        rList.push(response.data.account.role_name[r].id)
                   }
                    this.accountForm.roles = rList;
               }

           }).catch((error) => {
                console.log(error)
           });
       },

        saveAccount(formName, t) {
            let url = '';
            //判断是否超级管理员
            if (this.$_getActionKeyListBoolean(['/account/save'])){
                if (t == 'save' && this.showEdit) {
                    url = '/admin/account/updateVue';
               } else if (t == 'save' && !this.showEdit) {
                    url = '/admin/account/saveVue';
               } else if (t == 'batchSaveOrUpdate') {
                    url = '/admin/account/batchSaveOrUpdate';
               }
           }else{
                url = '/admin/account/myPassWord';
           }

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: '饮茶先啦，数据处理中，请耐心等待!',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                   });
                    this.$axios({
                        method: 'post',
                        url: url,
                        data: this.accountForm,
                   }).then((response) => {          //这里使用了ES6的语法
                        loading.close();
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'success'
                           });
                            this.dialogFormVisible = false;
                            this.dialogBatch = false;
                            this.$_findRoleVersion();
                           /* console.log('this.$parent',this.$parent);
                            console.log('this.$listeners',this.$listeners);
                            console.log(this.$listeners['getAccountList'])*/
                            if (this.isTable){
                                this.$parent.getAccountList();
                           }
                       } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            return
                       }
                   }).catch((error) => {
                        console.log(error)
                        loading.close();
                        this.$message({
                            showClose: true,
                            message: error,
                            type: 'error'
                       });
                   });
               } else {
                    //console.log('error submit!!');
                    return false;
               }
           });
       },

   }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 80% !important;
   }

    /deep/ .el-form-item__error {
        position: initial !important;
   }

    .el-tag + .el-tag {
        margin-left: 10px;
        margin-top: 10px;
   }

    /deep/ textarea[readonly] {
        background-color: #EEEEEE;
   }
</style>
